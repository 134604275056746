import { logger } from '@sentry/utils'
import fetch from 'cross-fetch'
import { getCurrency } from 'lib/locale'
import router from 'next/router'
import React, { createContext, ReactNode, useEffect, useState } from 'react'
import { Currency } from 'types/enrolment'
import { SSR_COUNTRY } from '../../config/locale'
import createContextHook from '../../lib/createContextHook'
import { LocationResponse } from './LocationResponse'

const getStorageItem = (name: string): string | null => {
  if (typeof window === 'undefined') {
    return null
  }
  const value = window.localStorage.getItem(name)
  if (value && value !== 'undefined' && value !== 'null') {
    return value
  }
  return null
}

interface LocationContextInterface extends LocationResponse {
  loading?: boolean
  currency?: string
  changeCountry?: (country: string) => void
}

const LocationContext = createContext<LocationContextInterface>({
  country: SSR_COUNTRY,
})

export const useLocation = createContextHook(
  LocationContext,
  'LocationContextInterface',
  'useLocation',
)

const LocationProvider = ({ children }: { children: ReactNode }) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [location, setLocation] = useState<LocationResponse>({
    country: SSR_COUNTRY,
  })
  const [currency, setCurrency] = useState<string>(Currency.NZD)

  // Change full location, country, and currency
  const changeLocation = (location: LocationResponse) => {
    setLocation(location)
    setCurrency(getCurrency(location.country, location.region))
    window.localStorage.setItem('loc', JSON.stringify(location))
    window.localStorage.setItem('country', location.country)
  }

  // Country toggle
  const changeCountry = (country: string) => {
    changeLocation({ country })
  }

  useEffect(() => {
    // If there's a country code in the URL or stored in cookies, update the state
    const { countrycode } = router.query // Extract countrycode from URL
    if (countrycode && countrycode !== 'DELETE') {
      changeLocation({ country: countrycode as string })
      return
    }

    const locationStoredValue = getStorageItem('loc')
    if (locationStoredValue) {
      changeLocation(JSON.parse(locationStoredValue) as LocationResponse)
      return
    }

    const countryStoredValue = getStorageItem('country')
    if (countryStoredValue && !locationStoredValue) {
      changeLocation({
        country: countryStoredValue,
      })
      return
    }

    // Async loading
    setLoading(true)
    fetch(process.env.NEXT_PUBLIC_COUNTRY_DETECTOR || '/api/get-country')
      .then((res) => res.json())
      .then((location: LocationResponse) => {
        // Store result if successful
        if (location.country) {
          changeLocation(location)
        }
      })
      .catch((error) => {
        logger.error(error)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [location?.country])

  return (
    <LocationContext.Provider
      value={{
        ...location,
        loading,
        currency,
        changeCountry,
      }}
    >
      {children}
    </LocationContext.Provider>
  )
}

export default LocationProvider
