import { Course, CourseIntake } from 'types/course'

export interface ProfileFields {
  dateOfBirth: string
  highestQualification: string
  qualificationProvider: string
}

export enum CourseEnrolResult {
  SUCCESS = 'SUCCESS',
  INCOMPLETE_REQUIRES_ACTION = 'INCOMPLETE_REQUIRES_ACTION',
  FAIL_PAYMENT = 'FAIL_PAYMENT',
  FAIL_ALREADY_ENROLLED = 'FAIL_ALREADY_ENROLLED',
  FAIL_NOT_PUBLIC = 'FAIL_NOT_PUBLIC',
  FAIL_NOT_FREE_COURSE = 'FAIL_NOT_FREE_COURSE',
  FAIL_NOT_PAID_COURSE = 'FAIL_NOT_PAID_COURSE',
  FAIL_CAN_NOT_ENROL = 'FAIL_CAN_NOT_ENROL',
  STATUS_FAIL_CODE_NOT_VALID = 'FAIL_CODE_NOT_VALID',
  STATUS_FAIL_CODE_EXPIRED = 'FAIL_CODE_EXPIRED',
  STATUS_FAIL_CODE_USED = 'FAIL_CODE_USED',
  STATUS_FAIL_CODE_INVALID_COURSE = 'FAIL_CODE_INVALID_COURSE',
}

export interface CourseEnrolResponse {
  status: CourseEnrolResult
  message: string
  enrolment: Enrolment
}

export type FreeCourseEnrolResponse = CourseEnrolResponse

export interface PaidCourseEnrolResponse extends CourseEnrolResponse {
  paymentIntentClientSecret?: string
}

export interface ValidatePromoCodeResponse {
  status: CourseEnrolResult
  message: string
  price: number
  discountedAmount: number
  discountedPercentage: number
  total: number
}

export enum Currency {
  NZD = 'NZD',
  AUD = 'AUD',
  USD = 'USD',
}

export enum EnrolmentStatus {
  NEW = 'NEW',
  SUBMITTED = 'SUBMITTED',
  WITHDRAWN = 'WITHDRAWN',
  ACCEPTED = 'ACCEPTED',
  CANCELLED = 'CANCELLED',
}

export interface Scholarship {
  id: number | string
  name: string
  slug: string
}

export interface ScholarshipInstance {
  id: number | string
  scholarship: Scholarship
}

export interface Enrolment {
  id: number | string
  status: EnrolmentStatus
  course: Course
  courseIntake: CourseIntake
  canUpdate: boolean
  canPay: boolean
  hasStatementOfIntent: boolean
  approvedScholarshipInstance?: ScholarshipInstance
}
