import { Context, useContext } from 'react'

export const createContextHook =
  <C>(context: Context<C>, contextName: string, hookName: string) =>
  () => {
    const ctx = useContext(context)
    if (ctx === undefined) {
      throw new Error(
        `${hookName} must be used within a ${contextName} Provider`,
      )
    }
    return ctx as NonNullable<C>
  }

export default createContextHook
