import { signIn, useSession } from 'next-auth/client'
import React, { useEffect } from 'react'

// Auth component: https://github.com/nextauthjs/next-auth/issues/1210#issuecomment-782630909
const Auth = ({
  children,
  requireAuth = false,
}: { children: React.ReactNode; requireAuth?: boolean }) => {
  const [session, loading] = useSession()
  const isUser = !!session?.user

  useEffect(() => {
    if (loading) {
      // Do nothing while loading
      return
    }
    if (!isUser && requireAuth) {
      // If authentication is required and user is not authenticated, force log in
      signIn()
    }
  }, [isUser, loading, requireAuth])

  if (requireAuth && !isUser) {
    // Session is being fetched, or no user.
    // If no user, useEffect() will redirect.
    return null
  }

  return <>{children}</>
}

export default Auth
