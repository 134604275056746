import { Currency } from 'types/enrolment'
import { countriesToSeeNZ, regionsToSeeNZ } from '../config/locale'

export const getCurrency = (country = '', region = ''): string => {
  switch (true) {
    case countriesToSeeNZ.includes(country):
    case regionsToSeeNZ.includes(region):
      return Currency.NZD

    case country === 'AU':
      return Currency.AUD

    default:
      return Currency.USD
  }
}
