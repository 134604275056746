import {
  type ApolloClient,
  ApolloProvider,
  type NormalizedCacheObject,
} from '@apollo/client'
import { useApollo } from 'lib/apolloClient'
import { appWithTranslation } from 'next-i18next'
import 'styles/globals.css'
import 'styles/fonts.css'
import Auth from 'components/Auth'
import Shapes from 'components/Shapes/Shapes'
import { Provider } from 'next-auth/client'
import Head from 'next/head'
import Script from 'next/script'
import React, { useEffect } from 'react'
import TagManager from 'react-gtm-module'
import { RecoilRoot } from 'recoil'
import { CustomAppProps } from 'types'
import LocationProvider from '../context/Location/LocationProvider'
import SettingsContext from '../context/SettingsProvider/SettingsContext'

// Memoized content component to prevent unnecessary re-renders
const AppContent = React.memo(function AppContent({
  Component,
  pageProps,
  apolloClient,
}: CustomAppProps & {
  apolloClient: ApolloClient<NormalizedCacheObject>
}) {
  const getLayout = Component.getLayout || ((page: React.ReactElement) => page)
  const token = process.env.NEXT_PUBLIC_GOOGLE_API_KEY
    ? encodeURIComponent(process.env.NEXT_PUBLIC_GOOGLE_API_KEY)
    : null

  return (
    <RecoilRoot>
      <LocationProvider>
        <SettingsContext.Provider value={pageProps.settings}>
          <Provider
            session={pageProps.session}
            options={{
              clientMaxAge: 2 * 60 * 60,
            }}
          >
            <ApolloProvider client={apolloClient}>
              <Head>
                <meta
                  name='viewport'
                  content='width=device-width, initial-scale=1.0, maximum-scale=1.0,user-scalable=0'
                />
                <meta name='referrer' content='no-referrer-when-downgrade' />
                <link
                  rel='apple-touch-icon'
                  sizes='180x180'
                  href='/apple-touch-icon.png'
                />
                <link
                  rel='icon'
                  type='image/png'
                  sizes='32x32'
                  href='/favicon-32x32.png'
                />
                <link
                  rel='icon'
                  type='image/png'
                  sizes='16x16'
                  href='/favicon-16x16.png'
                />
                <link rel='manifest' href='/site.webmanifest' />
              </Head>

              {process.env.NEXT_PUBLIC_MARKETO_URL && (
                <Script
                  src={`${process.env.NEXT_PUBLIC_MARKETO_URL}/js/forms2/js/forms2.min.js`}
                  strategy='beforeInteractive'
                />
              )}

              {token && (
                <Script
                  id='googlemaps'
                  type='text/javascript'
                  strategy='beforeInteractive'
                  src={`https://maps.googleapis.com/maps/api/js?key=${token}&libraries=places`}
                />
              )}

              <Auth requireAuth={Component.auth}>
                {getLayout(<Component {...pageProps} />)}
              </Auth>

              <Shapes />
            </ApolloProvider>
          </Provider>
        </SettingsContext.Provider>
      </LocationProvider>
    </RecoilRoot>
  )
})

function MyApp(props: CustomAppProps) {
  // Initialize Apollo
  const apolloClient = useApollo(props.pageProps)

  // GTM initialization
  useEffect(() => {
    if (process.env.NEXT_PUBLIC_GTM) {
      TagManager.initialize({
        gtmId: process.env.NEXT_PUBLIC_GTM,
      })
    }
  }, [])

  return <AppContent {...props} apolloClient={apolloClient} />
}

export default appWithTranslation(MyApp)
