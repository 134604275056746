import { ApolloClient, InMemoryCache, from } from '@apollo/client'
import type { DefaultContext } from '@apollo/client/core'
import { BatchHttpLink } from '@apollo/client/link/batch-http'
import { setContext } from '@apollo/client/link/context'
import merge from 'deepmerge'
import isEqual from 'lodash/isEqual'
import { GetServerSidePropsContext } from 'next'
import { getSession } from 'next-auth/client'
import { useMemo } from 'react'
import possibleTypes from './possibleTypes.json'

export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__'

// Configurable origin
const origin =
  typeof window === 'undefined'
    ? (process.env.NEXT_GRAPHQL_SSR_ORIGIN as string)
    : window.location.origin

function createApolloClient(
  ctx?: GetServerSidePropsContext,
  token?: string,
): ApolloClient<any> {
  const authLink = setContext(
    async (_, { headers, ...othercontext }: DefaultContext) => {
      let bearerToken = null
      if (token) {
        bearerToken = token
      } else if (typeof window !== 'undefined') {
        const session = await getSession({ req: ctx?.req })
        bearerToken = session?.token
      }
      return {
        ...othercontext,
        headers: {
          ...(headers || {}),
          accept: 'application/json',
          authorization: bearerToken ? `Bearer ${bearerToken}` : '',
        },
        _,
      }
    },
  )
  // HTTP link
  const httpLink = new BatchHttpLink({
    uri: process.env.NEXT_PUBLIC_GRAPHQL_ENDPOINT, // Server URL (must be absolute)
    credentials: 'omit',
    batchMax: 5, // no more than 5 operations per batch
    batchInterval: 50, // ms to wait for batched queries
    headers: {
      origin,
    },
  })

  // Generate client
  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link: from([authLink, httpLink]),
    connectToDevTools: process.env.NODE_ENV === 'development',
    cache: new InMemoryCache({
      possibleTypes,
      typePolicies: {
        Query: {
          fields: {
            getNotifications: {
              read(data) {
                return data
              },
              merge(existing, incoming, { args }) {
                const { page = 0 } = args || {}
                let data = existing?.data || []
                if (incoming.data) {
                  // strip rows already cached
                  const newData = incoming.data.filter((x: any) => {
                    return !data.data.find((y: any) => x.__ref === y.__ref)
                  })

                  if (page === 0) {
                    // on first load or found new posts on poll, prepend results
                    data = [...newData, ...data]
                  } else {
                    // on clicking load more, append results
                    data = [...data, ...newData]
                  }
                }

                return {
                  __typename: 'UserNotificationPaginator',
                  paginatorInfo: incoming.paginatorInfo,
                  data,
                }
              },
            },
          },
        },
      },
    }),
  })
}

export function initializeApollo(
  initialState: null | { [key: string]: any } = null,
  ctx?: GetServerSidePropsContext,
  token?: string,
) {
  const apolloClient = createApolloClient(ctx, token)

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = apolloClient.extract()

    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    const data = merge(initialState, existingCache, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter((d) =>
          sourceArray.every((s) => !isEqual(d, s)),
        ),
      ],
    })

    // Restore the cache with the merged data
    apolloClient.cache.restore(data)
  }

  return apolloClient
}

export function addApolloState(
  client: ApolloClient<any>,
  pageProps: { props: { [key: string]: any } },
) {
  if (pageProps?.props) {
    pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract()
  }
  return pageProps
}

export function useApollo(pageProps: { [key: string]: any }) {
  const state = pageProps[APOLLO_STATE_PROP_NAME]
  const store = useMemo(() => initializeApollo(state), [state])
  return store
}
