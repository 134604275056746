import { createContext, useContext } from 'react'

type SettingsState = { [key: string]: string | null }

const SettingsContext = createContext<SettingsState>({})

export const useSettings = () => {
  const settings = useContext(SettingsContext)
  return (name: string, def?: string) => settings?.[name] || def || ''
}

export default SettingsContext
